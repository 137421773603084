
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = []

  get infoId () {
    return this.$route.params.id as string || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.engineering.selectEngineeringInfoByInfoId, { infoId: this.infoId }).then(res => {
      if (res) {
        this.info = res
      }
    })
  }
}
